@use '@angular/material' as mat;

$taures-typography-config: mat.m2-define-typography-config(
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400)
);

$taures-info-secondary-blue-800: #4C719C;
$taures-information-color: $taures-info-secondary-blue-800; // deprecated

$help-accent-yellow-700: #FFC107;
$taures-freude-gelb: $help-accent-yellow-700;

$taures-neutrals: mat.m2-define-palette((
  50:  #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  contrast: (
  )
), 800, 100, 900, 800);

$taures-typo-black: mat.m2-get-color-from-palette($taures-neutrals, 900);
$taures-typo-white: white;

$taures-primary: mat.m2-define-palette((
  50:  #E6EAF0,
  100: #C0CADB,
  200: #99A7C3,
  300: #7286AA,
  400: #546C99,
  500: #34548B,
  600: #2E4C82,
  700: #254377,
  800: #1F396A,
  900: #162952,
  contrast: (
    50: $taures-typo-black,
    100: $taures-typo-black,
    200: $taures-typo-black,
    300: $taures-typo-black,
    400: $taures-typo-black,
    500: $taures-typo-white,
    600: $taures-typo-white,
    700: $taures-typo-white,
    800: $taures-typo-white,
    900: $taures-typo-white,
  )
), 800, 100, 900, 800);

$taures-accent: mat.m2-define-palette((
  main: $taures-info-secondary-blue-800,
  lighter: #d9e3ef,
  darker: #6485b4,
  200: #81a1c8, // For slide toggle,
  contrast : (
    main: $taures-typo-white,
    lighter: $taures-typo-black,
    darker: $taures-typo-white,
  )
), main, lighter, darker, main);

$taures-success: mat.m2-define-palette((
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #4caf50,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  contrast: (
    50: $taures-typo-black,
    100: $taures-typo-black,
    200: $taures-typo-black,
    300: $taures-typo-black,
    400: $taures-typo-black,
    500: $taures-typo-white,
    600: $taures-typo-white,
    700: $taures-typo-white,
    800: $taures-typo-white,
    900: $taures-typo-white,
  )
), 800, 100, 900, 800);

$taures-warning: mat.m2-define-palette((
  50: #fcf2e3,
  100: #f9dfb8,
  200: #f6ca8b,
  300: #f2b55f,
  400: #f0a544,
  500: #ed9734,
  600: #e88d31,
  700: #e17e2d,
  800: #d9712a,
  900: #cd5b27,
  contrast: (
    50: $taures-typo-black,
    100: $taures-typo-black,
    200: $taures-typo-black,
    300: $taures-typo-black,
    400: $taures-typo-black,
    500: $taures-typo-black,
    600: $taures-typo-black,
    700: $taures-typo-black,
    800: $taures-typo-black,
    900: $taures-typo-black,
  )
), 800, 100, 900, 800);

$taures-danger: mat.m2-define-palette((
  50: #feebed,
  100: #fcccd0,
  200: #e99a98,
  300: #dd7370,
  400: #e6554d,
  500: #e94733,
  600: #db3d32,
  700: #c9342d,
  800: #bc2e26,
  900: #ad241b,
  contrast: (
    50: $taures-typo-black,
    100: $taures-typo-black,
    200: $taures-typo-black,
    300: $taures-typo-black,
    400: $taures-typo-black,
    500: $taures-typo-white,
    600: $taures-typo-white,
    700: $taures-typo-white,
    800: $taures-typo-white,
    900: $taures-typo-white,
  )
), 800, 100, 900, 800);

$taures-theme: mat.m2-define-light-theme((
  color: (
    primary: $taures-primary,
    accent: $taures-accent,
    warn: $taures-danger,
  ),
  typography: $taures-typography-config,
  density: 0,
));



@use '@angular/material' as mat;
@use "@taures/angular-commons/taures.theme" as theme;
@use '@taures/angular-commons/taures.styles';
@use 'spinner';
@use 'material-design-icons/iconfont/material-icons.css';

$taures-primary: mat.get-theme-color(theme.$taures-theme, primary);
$taures-secondary: mat.get-theme-color(theme.$taures-theme, accent);

body {
  padding: 0;
  margin: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
}

.taures-blue-1 {
  background-color: $taures-primary !important;
  color: theme.$taures-typo-white !important;
}

.taures-blue-1-text {
  color: $taures-primary;
}

.taures-blue-2 {
  background-color: $taures-secondary !important;
  color: theme.$taures-typo-white !important;
}

.taures-warn {
  --mdc-chip-elevated-disabled-container-color: #{mat.m2-get-color-from-palette(theme.$taures-warning, 800)};
  --mdc-chip-disabled-label-text-color: #{mat.m2-get-contrast-color-from-palette(theme.$taures-warning, 800) !important};
  --mdc-chip-label-text-weight: bold;
}

tr-konzept-footer {
  height: 2.5rem;
}

.mdc-button {
  letter-spacing: 0;
}

tr-external-contract-detail {
  background-color: theme.$taures-typo-white;
}

mat-chip-set {
  *, ::before, ::after {
    border-style: none;
  }
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-size: 12px;
  --mdc-chip-label-text-line-height: 16px;
}

.mat-mdc-select, .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input, .mat-mdc-form-field, .mat-mdc-floating-label, .mat-mdc-option {
  font-size: 1rem !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 4px 4px 4px 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: black;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-line-height: 20px;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fff;
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-dialog-title {
  --mdc-dialog-subhead-weight: 400;
}

.mdc-checkbox {
  --mdc-checkbox-selected-focus-state-layer-color: rgba($taures-primary, 0.12);
  --mdc-checkbox-selected-focus-state-layer-opacity: 1;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-disabled-selected-icon-color: rgba($taures-primary, 0.38);

  .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
    border: solid 1px $taures-primary;
  }
}

.blue-radio-label .mat-mdc-radio-checked label {
  color: $taures-primary;
}

.radio-medium-label label {
  font-weight: 500;
}

.top-aligned-checkbox .mdc-form-field {
  .mdc-checkbox {
    margin-left: -11px;
  }
}

.mat-select-trigger {
  --mat-select-trigger-text-size: 14px;
}

.mat-select-value-text {
  white-space: normal !important;
}


.mat-dialog-actions {
  margin-bottom: -6px !important;
}

.active-contract-row {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: -20px !important;
  margin-left: -20px !important;
}

.active-contract-row:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.active-contract-row:first-child {
  padding-top: 0;
}

.active-contract-col {
  margin-left: 5px;
}

mat-radio-button .mdc-form-field .mdc-label {
  padding-left: 4px;
  font-size: 14px;
}

.py-075 {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
}

.px-075 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.py-125 {
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
}

.ml-125 {
  margin-left: 1.25rem !important;
}

html, body {
  height: 100%;
  font-size: 14px;
}

mat-checkbox.ng-invalid {
  .mdc-checkbox__background {
    border-color: mat.get-theme-color(theme.$taures-theme, 'warn');
  }

  .mdc-label {
    color: mat.get-theme-color(theme.$taures-theme, 'warn');
  }
}

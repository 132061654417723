@use '@angular/material' as mat;
@use "@taures/angular-commons/taures.theme" as theme;

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .text-block {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .reload {
    cursor: pointer;
    max-width: 400px;
    text-transform: uppercase;
    padding: 12px;
    border: 0;
    border-radius: 2px;
    font-weight: 400;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);
  }
}

.spinner {
  width: 50px;
  height: 50px;
  background-color: mat.get-theme-color(theme.$taures-theme, primary);

  margin: 50px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px)
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg)
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
